import React from "react";
import {
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    TextField
} from "@mui/material";
import {ModalComponentProps} from "./CustomerComponentProps";
import {useFormik} from "formik";
import {Trans} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import '../../../index.scss';
import dayjs from "dayjs";
import {GenerateInvoiceRequestT} from "../../Invoices/core/GenerateInvoiceRequestT";
import {useGenerateInvoice} from "../../Invoices/hook/useInvoices";


function ContactDetail(props: ModalComponentProps<void>) {
    const [initialValue] = React.useState<GenerateInvoiceRequestT>({
        customerId: props.customerId,
        billingDate: dayjs().format("YYYY-MM-DD"),
        fromPeriod: null,
        toPeriod: null
    });

    const useGenerateInvoiceHook = useGenerateInvoice();
    const [enableCustomPeriod, setEnableCustomPeriod] = React.useState(false)

    const formik = useFormik({
        initialValues: initialValue,
        enableReinitialize: true,
        onSubmit: (values: GenerateInvoiceRequestT) => {
               values = {
                   ...values,
                   customerId: props.customerId
               }

            useGenerateInvoiceHook.generate(values).then(() => {
                props.onClose();
            })
        },
    });

    return  <Dialog
        open={props.open}
        onClose={_ => {return;}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        PaperProps={{
            component: 'form',
            onSubmit: formik.handleSubmit,
        }}
    >
        <DialogTitle>
            <Trans>INVOICE_GENERATE.TITLE</Trans>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            <div style={{display:"flex", flexDirection: "column", gap: "1rem"}}>
                <TextField
                    fullWidth
                    variant="standard"
                    type="date"
                    id="billingDate"
                    name="billingDate"
                    label={<Trans>INVOICE_GENERATE.FIELDS.BILLING_DATE</Trans>}
                    value={formik.values.billingDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputLabelProps={{ shrink: true }}
                />
                <FormControlLabel
                    label={<Trans>INVOICE_GENERATE.FIELDS.CUSTOM_PERIOD</Trans>}
                    control={
                        <Checkbox
                            id="enableCustomPeriod"
                            name="enableCustomPeriod"
                            value={enableCustomPeriod}
                            onChange={e => setEnableCustomPeriod(Boolean(e.target.checked))}
                        />
                    } />
                {enableCustomPeriod && <>
                    <TextField
                        fullWidth
                        variant="standard"
                        type="date"
                        id="fromPeriod"
                        name="fromPeriod"
                        label={<Trans>INVOICE_GENERATE.FIELDS.FROM_PERIOD</Trans>}
                        value={formik.values.fromPeriod}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{shrink: true}}
                    />
                    <TextField
                        fullWidth
                        variant="standard"
                        type="date"
                        id="toPeriod"
                        name="toPeriod"
                        label={<Trans>INVOICE_GENERATE.FIELDS.TO_PERIOD</Trans>}
                        value={formik.values.toPeriod}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{shrink: true}}
                    />
                </>
                }
            </div>
            <DialogActions>
                <Button color="error" onClick={props.onClose}><Trans>COMMON.CANCEL</Trans></Button>
                <Button type="submit"><Trans>COMMON.SAVE</Trans></Button>
            </DialogActions>
        </DialogContent>
    </Dialog>
}

export default ContactDetail;