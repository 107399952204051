import {useNavigate} from "react-router-dom";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import {Invoice} from "../core/Invoice";
import {useTranslation} from "react-i18next";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {ROUTES} from "../../../routes/ROUTES";
import DownloadIcon from '@mui/icons-material/Download';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {DOWNLOAD_FORMAT, useDownload} from "../hook/useInvoices";
import {currencyFormatter} from "../../../core/utils";
import {Tooltip} from "@mui/material";
import {calculateTotalInvoiceAmount} from "../core/utils/InvoiceUtils";

interface InvoicesGridProps {
    invoices: Invoice[];
    onSelectionChange: (rows: number[]) => void
    disableSelection?: boolean;
}


function InvoicesGrid(props: InvoicesGridProps) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const downloadHook = useDownload();

    const columns: GridColDef<Invoice>[] = [
        {
            field: 'details', headerName: '', type: 'actions',
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem label="Dettagli"
                                     icon={<Tooltip title={t('COMMON.DETAILS').toString()}><InfoIcon /></Tooltip>}
                                     onClick={() => navigate(ROUTES.INVOICE_DETAIL + params.row.invoiceId) }
                />
            ]
        },
        { field: 'customer.customerCode', headerName: t("INVOICES_LIST.FIELDS.CUSTOMER_CODE"), width: 200, valueGetter: (params) => params.row.customer?.customerCode },
        { field: 'customer.businessName', headerName: t("INVOICES_LIST.FIELDS.CUSTOMER_NAME"), flex: 1, valueGetter: (params) => params.row.customer?.businessName },
        { field: 'invoiceNumber', headerName: t("INVOICES_LIST.FIELDS.NUMBER") },
        { field: 'invoiceTotal', headerName: t("INVOICES_LIST.FIELDS.TOTAL"), valueGetter: (params) => currencyFormatter(calculateTotalInvoiceAmount(params.row))},
        { field: 'startPeriod', headerName: t("INVOICES_LIST.FIELDS.START_PERIOD")},
        { field: 'endPeriod', headerName: t("INVOICES_LIST.FIELDS.END_PERIOD")},
        {
            field: 'download', headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams<Invoice>) => [
                <GridActionsCellItem label="download" icon={<Tooltip title={t('COMMON.DOWNLOAD_XML').toString()}><DownloadIcon /></Tooltip>}
                                     onClick={() => downloadHook.download(params.row.invoiceId, DOWNLOAD_FORMAT.XML)}
                />
            ]
        },
        {
            field: 'download-pdf', headerName: '', type: 'actions', width: 50,
            getActions: (params: GridRowParams<Invoice>) => [
                <GridActionsCellItem label="download" icon={<Tooltip title={t('COMMON.DOWNLOAD_PDF').toString()}><FindInPageIcon /></Tooltip>}
                                     onClick={() => downloadHook.download(params.row.invoiceId, DOWNLOAD_FORMAT.PDF)}
                />
            ]
        },
    ]

    return <DataGridPro getRowId={(row: Invoice) => row.invoiceId}
                        rows={props.invoices}
                        columns={columns}
                        checkboxSelection={!props.disableSelection}
                        disableRowSelectionOnClick={props.disableSelection}
                        onRowSelectionModelChange={props.onSelectionChange}
                        hideFooter />

}

export default InvoicesGrid;