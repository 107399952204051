import React from 'react';
import {AxiosResponse} from "axios";
import SuspenseComponent from "../../Layout/SuspenseComponent";
import {Invoice, InvoiceStatus} from "../core/Invoice";
import {useInvoices} from "../hook/useInvoices";
import InvoicesGrid from "../components/InvoicesGrid";
import PageTitle from "../../SkinnableComponents/PageTitle/PageTitle";
import {calculateTotalInvoiceAmount} from "../core/utils/InvoiceUtils";
import Typography from "@mui/material/Typography";
import {Trans} from "react-i18next";
import {Button} from "@mui/material";
import {InvoicesStatusesMap} from "../../Customers/core/DataSources";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddTaskIcon from '@mui/icons-material/AddTask';
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../routes/ROUTES";

export interface InvoicesViewProps {
    mode: string;
}

enum InvoiceViewModes {
    APPROVED= 'approved',
    PENDING = 'pending'
}

function InvoicesView(props: InvoicesViewProps) {
    const [selection, setSelection] = React.useState<number[]>([]);
    const invoicesHook = useInvoices();
    const toDownloadHook = useInvoices();
    const navigate = useNavigate();

    const mapInvoices = React.useCallback((res: AxiosResponse<Invoice[]>): Invoice[] => {
        return res.data.map(i => ({
            ...i,
            invoiceTotal: calculateTotalInvoiceAmount(i)
        }));
    }, []);

    const reloadData = React.useCallback(() => {
        invoicesHook.get(InvoicesStatusesMap[props.mode], mapInvoices).then();
        toDownloadHook.get(InvoiceStatus.APPROVED, mapInvoices).then();
    }, [props.mode, mapInvoices]);

    const onHandleDownload = () => {
        if (invoicesHook.invoices.length) {
            invoicesHook.download(invoicesHook.invoices.map(i => i.invoiceId))
                .then(_ => reloadData())
        }
    }

    React.useEffect(() => {
        reloadData();
    }, [props.mode, reloadData])

    return <SuspenseComponent isLoading={invoicesHook.isLoading}>
        <PageTitle title={`INVOICES_LIST.TITLES.${props.mode?.toUpperCase()}`}>
                <span style={{display: 'flex', gap: '.7rem'}}>
                    {props.mode === InvoiceViewModes.PENDING &&
                        <>
                            <Button
                                aria-label="approve"
                                className="action-button"
                                disabled={!Boolean(selection.length)}
                                onClick={_ => invoicesHook.approve(selection).then(_ => reloadData())}
                            >
                                <AddTaskIcon></AddTaskIcon>
                                <Typography>
                                    <Trans>COMMON.APPROVE</Trans>
                                </Typography>
                            </Button>
                            <Button
                                className="action-button"
                                aria-label="download"
                                disabled={!Boolean(toDownloadHook.invoices.length)}
                                onClick={() => navigate(ROUTES.APPROVED_INVOICES)}
                            >
                                <CloudDownloadIcon></CloudDownloadIcon>
                                <Typography>
                                    <Trans>INVOICES_LIST.GO_TO_DOWNLOAD</Trans>
                                    {toDownloadHook.invoices.length ? ` (${toDownloadHook.invoices.length})` : ''}
                                </Typography>
                            </Button>
                        </>
                    }
                    {props.mode ===  InvoiceViewModes.APPROVED &&
                        <Button
                            className="action-button"
                            aria-label="download"
                            disabled={!Boolean(toDownloadHook.invoices.length)}
                            onClick={onHandleDownload}
                        >
                            <CloudDownloadIcon></CloudDownloadIcon>
                            <Typography>
                                <Trans>COMMON.DOWNLOAD</Trans>
                                {toDownloadHook.invoices.length ? ` (${toDownloadHook.invoices.length})` : ''}
                            </Typography>
                        </Button>
                    }
                </span>
        </PageTitle>
        <InvoicesGrid
            disableSelection={props.mode === InvoiceViewModes.APPROVED}
            invoices={invoicesHook.invoices}
            onSelectionChange={setSelection}
        />
    </SuspenseComponent>
}

export default InvoicesView;